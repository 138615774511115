import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet";

import Slider from "react-slick";

// Import css files
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export default function SliderComponent() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <section className="slider-section">
            <Helmet>
                <link
                    rel="stylesheet"
                    type="text/css"
                    charset="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />

                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
            </Helmet>
            <Slider {...settings}>
                <div>
                    <StaticImage src="../../images/slider-index/slider1_bg.png" quality={100} layout="fullWidth" className="sliderImg" alt="Satisfying the World's Needs through Technology" />
                </div>
                <div>
                    <StaticImage src="../../images/slider-index/slider2_bg.png" quality={100} layout="fullWidth" className="sliderImg" alt="Highly Competent Personnel built on a strong foundation" />

                </div>
                <div>
                    <StaticImage src="../../images/slider-index/slider4_bg.png" quality={100} layout="fullWidth" className="sliderImg" alt="Award Winning Service" />
                </div>
                <div>
                    <StaticImage src="../../images/slider-index/slider3_bg.png" quality={100} layout="fullWidth" className="sliderImg" alt="Over 20 years Experience for Networking Systems and Telecommunications" />
                </div>
            </Slider>
        </section>
    );
}