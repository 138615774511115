import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SliderComponent from "../components/index/slider"
import Seo from "../components/seo"

import { FaArrowCircleRight } from "react-icons/fa"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <SliderComponent />
    <section>

      <div className="index-services-wrapper">
        {/* Telecommunications */}
        <div className="index-services">
          <h1>Telecommunications</h1>
          <div>
            <StaticImage src="../images/index/1_microwave.jpg" quality={100} width={200} layout="constrained" className="index-image" alt="Tower Erection" />
            <StaticImage src="../images/index/2_tower_erection_australia.jpg" quality={100} layout="constrained" width={200} className="index-image" alt="Microwave Tower" />
          </div>
        </div>

        {/* System Solutions and Integration */}
        <div className="index-services">
          <h1>System Solutions & Integration</h1>
          <div>
            <StaticImage src="../images/index/integration1.jpg" quality={100} width={200} layout="constrained" className="index-image" alt="Structured Cabling 1" />
            <StaticImage src="../images/index/integration2.jpg" quality={100} layout="constrained" width={200} className="index-image" alt="Structured Cabling 2" />
          </div>
        </div>


      </div>

    </section>

    <section className="section-center">
      <div className="index-second">
        <p>
          Martindale Technologies boasts a wide array of industry partners ranging from network equipment providers and cabling distributors
        </p>

        <Link to="/services/" ><button className="btn">View Our Services
          <FaArrowCircleRight className="btn-icon" /></button></Link>
      </div>
    </section>
  </Layout>
)

export default IndexPage
